@use "../../style_vars.scss" as *;

.eg-button-container {
    height: calc(100% - 2em);
    width: 20%;
    background-color: black;
    background-color: $main-bg-color-80-alpha;
    border-radius: 1em;
    padding: 1em;
}
