@use "../../style_vars.scss" as *;
.map {
    display: block;
    position: relative;
    width: 62%;
    margin-left: 5%;
    height: auto;
}

.ussr-img {
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
}
