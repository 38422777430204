html {
    height: 100%;
}

body {
    height: 100%;
}

#root {
    height: 100%;
}

.App {
    text-align: center;
}