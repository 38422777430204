.eg-city {
    position: absolute;
    display: flex;
    place-content: center;
    align-items: center;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: small;
    font-weight: 900;
}

.eg-city-dot {
    width: 10px;
    height: 10px;
    border: 1px solid black;
    border-radius: 50%;
    background-color: white;
}
