@use "../../style_vars.scss" as *;

.homepage-link {
    position: absolute;
    top: 0.5em;
    left: 0.5em;
    padding: 0.5em;
    width: fit-content;
    background-color: $main-bg-color;
    color: white;
}

.homepage-link:hover {
    color: black;
}

a {
    color: white;
    text-decoration: none;
}
